.services__prices {
    background: #EFEFEF;
    padding: 120px 0;
    
    .services__prices__wrapper {
        margin: auto;
    }
    .services__prices__header{
        font-weight: 800;
        font-size: 48px;
        line-height: 150%;
        margin-bottom: 47px;
    }
    
    .services__block {
        display: flex;
    }
    
    .services__list__wrapper {
        width: 320px;
        margin-right: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        
        .services__service__name{
            font-weight: normal;
            font-size: 18px;
            line-height: 120%;
            width: 330px;
            height: 50px;
            display: flex;
            align-items: center;
            padding: 0 10px;
            box-sizing: border-box;
            border-radius: 10px;
            transition: all 300ms linear;
            
            img, svg {
                width: 20px;
                height: auto;
                margin-right: 12px;
            }
            svg path { 
                fill: #111;
                }
            &.--active_li, &:focus {
                background: #ED1C24;
                color: #fff;
                transition: all 300ms linear;
                font-weight: 800;
            }
            &.--active_li svg path {
                fill: #fff;
            }
            &.--active_li::after {
                content: url('../img/svg/arrow.svg');
                width: 8px;
                height: 12px;
                margin-left: auto;
                display: flex;
            }
            
            &:hover {
                background: #FFFFFF;
                color: #111;
                font-weight: normal;
                cursor: pointer;
                transition: background 300ms linear;
            }
            &:hover svg path { 
            fill: #111;
            }
            

        }
        
        .services__problem__wrapper {
            border: 3px solid #ED1C24;
            box-sizing: border-box;
            border-radius: 20px;
            padding: 17px 22px 22px;
            
            .problem__header {
                font-weight: 800;
                font-size: 24px;
                line-height: 120%;
                margin-bottom: 12px;
            }
            
            
        }
    }
    .service__name__chosen {
        font-weight: 800;
        font-size: 36px;
        line-height: 49px;
        background: #FFFFFF;
        border-radius: 10px 10px 0 0;
        padding: 16px 40px 10px;
       
    }
    .services__pricelist__wrapper.tab_container {
        width: 100%;
    }
    .services__pricelist {
        
        overflow-y: scroll;
        height: 680px;
        box-sizing: border-box;
        
        .service__item {
            background: #FFFFFF;
            padding: 25px 20px 25px 40px;
            box-sizing: border-box;
            transition: all 300ms linear;
            cursor: pointer;
            width: 100%;
            
            &:hover {
                background: #F2F2F2;
                transition: all 300ms linear;
            }
            &:hover .service__item__name {
                text-decoration: underline;
            }
        }
        .service__name__price {
            display: flex;
            justify-content: space-between;
            margin-bottom: 6px;
            align-items: baseline;
        }
        .service__name__price .service__item__name, 
         .service__name__price .service__item__price {
            
            font-size: 24px;
            line-height: 120%;
            
        }
        .service__name__price .service__item__name {
            width: 70%;
            font-weight: 600;
        }
        
        .service__item__description {
            font-size: 18px;
            line-height: 120%;
        }
        
        .service__name__price .service__item__price {
            font-weight: 800;
        }
        
    }
    
    .tab_content {
        display: none;
        
        &.active_tab {
            display: block;
        }
    }
    
     
    /* аккордеон скрыт в режиме вкладок */
     
    .tab__accordion__name{
        display: none;
    }
    
}

 
 

 
@media screen and (max-width: 1919px) {
    .services__prices {
        padding: 40px 0;
    }
    .services__prices .services__pricelist {
        height: 615px;
    }
    .services__prices .services__prices__header {
        margin-bottom: 25px;
    }
    .services__prices .services__pricelist .service__item {
        padding: 15px 40px;
    }
    .services__prices .services__pricelist .service__name__price .service__item__name, 
    .services__prices .services__pricelist .service__name__price .service__item__price{
        font-size: 18px;
        line-height: 150%;
    }
    .services__prices .services__pricelist .service__item__description {
        font-size: 16px;
        line-height: 150%;
    }
}

@media screen and (max-width: 1139px) {
    .services__prices .services__prices__header {
        text-align: center;
        margin-bottom: 30px;
    }
    
    .services__prices {
        padding-bottom: 60px;
    }
    
    .services__prices .services__list__wrapper {
        display: none;
    }
    .services__prices .service__name__chosen {
        display: none;
    }
    
    .services__prices .tab__accordion__name {
        display: flex;
        height: 50px;
        border: 1px solid #C4C4C4;
        border-radius: 10px;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
       box-sizing: border-box;
        align-items: center;
        padding: 7px 30px 7px 23px;
        transition: background 300ms linear;
        position: relative;
        cursor: pointer;
            &.active_tab {
                background: #ED1C24; 
                 color: #FFFFFF;
                 border: none;
                 transition: background 300ms linear;
                 svg path{
                     fill: #fff;
                 }
            }
            &.active_tab::after {
                content: url('../img/svg/arrow.svg');
                width: 8px;
                height: 12px;
                right: 15px;
                transform: rotate(90deg);
                position: absolute;
            }
            svg {
                width: 35px;
                height: 30px;
                margin-right: 30px;
                
            }
    }
    .services__prices .services__pricelist .service__item {
        border-radius: 10px;
    }
    .services__prices .services__pricelist {
        height: auto;
        overflow-y: auto;
    }
   
    
}
 

@media screen and (max-width: 767px) {
    
    .services__prices {
        padding: 25px 0 55px;
    }
    
    .services__prices .services__prices__header {
        font-size: 36px;
        margin-bottom: 25px;
    }
    
    .services__prices .tab__accordion__name {
        font-size: 20px;
    }
    
    
}
 

@media screen and (max-width: 539px) {
    
    .services__prices {
        padding: 30px 0 45px;
    }
    
    .services__prices .services__prices__header {
        font-size: 24px;
        margin-bottom: 15px;
    }
    
    .services__prices .tab__accordion__name {
        display: flex;
        flex-direction: column;
        height: 84px;
        padding: 12px 15px;
        font-size: 18px;
        text-align: left;
        justify-content: center;
        align-items: flex-start;
    }
    .services__prices .tab__accordion__name svg {
       margin: 0 auto 6px;
    }
    
    .services__prices .services__pricelist .service__item {
        padding: 15px;
    }
    
    .services__prices .services__pricelist .service__name__price .service__item__name, 
    .services__prices .services__pricelist .service__name__price .service__item__price {
        font-size: 14px;
    }
    .services__prices .services__pricelist .service__item__description {
        display: none;
    }
    .services__prices .services__pricelist .service__item__name {
        flex-basis: 63%;
    }
    .services__prices .services__pricelist .service__item__price {
        text-align: right;
    }
    
    .services__prices .tab__accordion__name.active_tab::after {
        bottom: 18px;
    }
}