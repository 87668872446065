.contacts {
    background: url('../img/map_bg.jpg');
    padding: 215px 0 70px;
    color: #fff;
    
    .contacts__header {
        font-weight: 800;
        font-size: 48px;
        line-height: 150%;
        margin-bottom: 70px;
    }
    

    
    
    .contacts__block {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        
        p, a {
          font-size: 16px;
        line-height: 150%;  
        color: #fff;
        }
        .phone, .address{
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
        
        .bolder {
                font-weight: 800;
                
            }
        .upper {
            text-transform: uppercase;
        }
        .address__header {
            font-size: 24px;
            line-height: 120%;
            margin-bottom: 15px;
        }
        .social__icons {
            display: flex;
        }
        
        .social__icons .social__link {
            margin-right: 30px;
            cursor: pointer;
        }
    }
    
    .map__block {
        margin-top: 60px;
        

        
        iframe {
            border-radius: 10px;
            border: none;
            height: 470px;
        }
    }
    
    
}
@media screen and (max-width: 1919px) {
    
    .contacts {
        padding: 40px 0;
    }
    .contacts .contacts__block a, .contacts .contacts__block p {
        font-size: 14px;

      }
      .contacts .contacts__block .upper {
          text-transform: none;
      }
      .contacts .map__block iframe {
          height: 380px;
      }
    
    
}

@media screen and (max-width: 1139px)  {
    .contacts .contacts__header {
        text-align: center;
        margin-bottom: 50px;
        
    }
    
    .contacts .contacts__block .address__header {
        margin-bottom: 25px;
    }
    
    .contacts .contacts__block {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .contacts .contacts__block a, .contacts .contacts__block p {
        font-size: 18px;
    }
    
    .contacts .contacts__block .contacts__phone{
        margin: 25px 0 15px;
    }
    
    
    .contacts .contacts__block .social__icons {
        justify-content: space-between;
        margin-top: 10px;
    }
    .contacts .contacts__block .social__icons .social__link {
        margin-right: 0;
    }
    
    .contacts .map__block {
        margin-top: 35px;
    }
    .contacts .map__block iframe {
        height: 495px;
    }
    
    
}

@media screen and (max-width: 767px) {
    
    .contacts {
        padding: 30px 0 15px;
    }
    
    .contacts .contacts__header {
        font-size: 36px;
        margin-bottom: 25px;
    }
    .contacts .contacts__block .address__header {
        font-size: 20px;
        margin-bottom: 15px;
    }
    .contacts .contacts__block a, .contacts .contacts__block p {
        font-size: 16px;
    }
    .contacts .contacts__block .contacts__phone {
        margin: 18px 0 13px;
    }
    .contacts .map__block iframe {
        height: 275px;
    }
    
}
@media screen and (max-width: 539px) {
    .contacts .map__block iframe {
        height: 290px;
    }
    
    .contacts .contacts__header {
        font-size: 24px;
    }
    .contacts .contacts__block a, .contacts .contacts__block p {
        font-size: 14px;
    }
    
}