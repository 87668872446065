.team {
    background: #010B22;
    color: #FFFFFF;
    padding: 220px 0 120px;
    position: relative;
    overflow: hidden;
    width: 100%;
    .team__wrapper{
        margin: auto;
       width: 1140px;
    }
    
    .team__bg__text {
        position: absolute;
        font-weight: 800;
        font-size: 20em;
        line-height: 150%;
        text-transform: uppercase;
        color: #FFFFFF;
        opacity: 0.03;
        top: 5%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 0;
        margin: auto;
    }
    
    .team__header {
        font-weight: 800;
        font-size: 48px;
        line-height: 150%;
        margin-bottom: 60px;
    }
    .btn.call_craftsman {
        margin: 60px auto 0;
    }
    
    .team__block {
        display: flex;
        justify-content: space-between;       
        width: 100%;
        height: 550px;
        overflow: hidden;
    }
    
    .team__item {
        display: flex;
        flex-direction: column;
        max-width: 250px !important;
       margin-right: 35px;
       min-width: 250px;
       width: 250px;

        .team__avatar {
            margin-bottom: 30px;
            width: 250px;
            height: 250px;
            img {
                border-radius: 10px;
                width: 100%;
                height: auto;
            }
        }
        
        .team__position {
            font-size: 16px;
            line-height: 150%;
            margin-bottom: 6px;
            opacity: 0.5;
            
            .job_icon {
                height: 12px;
                width: auto;
                display: inline-block ;
                margin-right: 5px;
                }
        }
        
        .team__name {
            font-weight: 800;
            font-size: 24px;
            line-height: 120%;
            margin-bottom: 10px;
        }
        .team__about__person {
            font-size: 18px;
            line-height: 120%;
        }
        
        &:last-child {
            margin-right: auto;
        }
        
    }
    
    .team__item:first-child .team__avatar img{
        border-radius: 50%;
        
    }
    .team__item:first-child .team__position {
        color: #8DC63F;
        opacity: 1;
        
        
    }
   
    .team__item:first-child .team__position::before {
        content: '';
        display: inline-block;
        background: #8DC63F;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 5px;
    }

    
}
@media screen and (max-width: 1919px) {
    .team {
        padding: 80px 0;
    }
    .team .team__wrapper {
        width: 1024px;
    }
    .team .team__bg__text {
        font-size: 160px;

    }
    .team .team__item {
        min-width: 225px;
        max-width: 225px;
        width: 225px;
        margin-right: 20px;
    }

    .team .team__item .team__avatar {
        width: 225px;
        height: 225px;
    }
    .team .team__item:first-child .team__avatar img {
        border-radius: 10px;
    }
    .team .team__item .team__position {
        margin-bottom: 0;
    }
    
    
    .team .team__item .team__name {
        font-size: 18px;
        line-height: 150%;
    }
   
    
    .team .btn.call_craftsman {
        margin-top: 40px;
        width: 300px;
        height: 65px;
    }
}

@media screen and (max-width: 1139px) {
    .team {
        padding: 45px 0 85px;
    }
    .team .team__wrapper {
        width: 100%;
    }
    .team .team__bg__text {
        display: none;
    }
    
    .team .team__header {
        text-align: center;
        margin-bottom: 75px;
    }
    .team .team__item {
        max-width: 250px;
        min-width: 250px;
        width: 250px;
        margin: 0 35px;
    }
    
    .team .team__item .team__avatar {
        width: 250px;
        height: 250px;
    }
    
    .team .team__item .team__position {
        font-size: 16px;
    }
    
    .team .team__item .team__name {
        font-size: 24px;
    }
    
  
    
    
}

@media screen and (max-width: 767px) {
    
    .team {
        padding: 30px 0 50px;
    }
    .team .team__header {
        font-size: 36px;
        margin-bottom: 55px;
    }
    .team .team__item .team__name {
        font-size: 20px;
    }
    
    .team .team__item .team__about__person {
        font-size: 16px;
    }
    .team .btn.call_craftsman {
        width: 200px;
height: 45px;
    }
    .team .team__block {
        height: 480px;
    }
    
}

@media screen and (max-width: 539px)  {
    
    .team .team__header {
        font-size: 24px;
        margin-bottom: 25px;
    }
    
    .team .btn.call_craftsman {
        margin-top: 15px;
    }
    
    
    
    
}