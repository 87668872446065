.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    display: none;
    
    
      
    &.show {
        display: block;
    }
    
    .close-btn {
        width: 24px;
        height: 24px;
        background: url('../img/svg/close-popup.svg') no-repeat;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }
    
    form {
        width: 360px;
        height: 400px;
        background: #fff;
        border-radius: 10px;
        padding: 55px 40px 40px;
        box-sizing: border-box;
        font-family: 'Nunito';
        
        
        legend {
            font-weight: 800;
            font-size: 24px;
            line-height: 120%;
            text-transform: uppercase;
            text-align: center;
            margin-bottom: 30px;
        }
        
        .form-group {
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            font-size: 16px;
        }
        
        input {
            height: 40px;
            width: 100%;
            font-family: 'Nunito';
            cursor: pointer;
            padding: 5px;
            box-sizing: border-box;
            font-size: 16px;
            text-transform: uppercase;
            border: 1px solid #000;
            
            &:invalid {
                border: 2px solid #ed1c24;
                box-shadow: none;
            }
            &:focus {
                border: 1px solid #ccc;
                box-shadow: none;
            }
        }
        input[type="submit"] {
            background: #ed1c24;
            border: none;
            color: #fff;
            text-transform: uppercase;
            font-weight: 800;
            border-radius: 10px;
            height: 45px;
            transition: background 300ms ease-in-out;
            margin-top: 10px;
            
            &:hover {
                transition: background 300ms ease-in-out;
                background: #C6171E;
            }
            
            
        }
        
    }
}

.black-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0,0,0,0.7);
    z-index: 3;
    display: none;
    
    &.show {
        display: block;
    }
}

@media screen and (max-width: 400px) {
    .popup form {
        width: 320px;
        padding: 55px 20px 40px;
    }
}
