@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;800&display=swap');
* {
    margin: 0;
    padding: 0;
}
body {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: normal;
}

.center {
    max-width: 1140px;
    margin: auto;
    position: relative;
    padding: 0 15px;
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
}

.btn {
    font-family: 'Nunito';
    width: 200px;
    height: 45px;
    background: #ED1C24;
    border: none;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    font-size: 16px;
    line-height: 120%;
    text-transform: uppercase;
    color: #fff;
    transition: background 300ms ease-in-out;
    
    &:hover {
        background: #C6171E;
        transition: background 300ms ease-in-out;
        cursor: pointer;
    }
}


nav {
    display: flex;
    margin: auto;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding-top: 16px;
    justify-content: center;
    align-items: center;
}
.nav__logo {
    width: 112px;
    height: 35px;
    margin-right: 42px;
    img {
        width: 100%;
        height: auto;
    }
}

.nav__list {
    display: flex;
    
    .nav__item {
        position: relative;
        margin: 0 32px 0 0;
    }
    
    .nav__item.nav__item--active::after {
        content: '';
        width: 60px;
        height: 3px;
        background: #8DC63F;
        display: flex;
        justify-content: center;
        position: relative;
        bottom: -3px;
        margin: auto;
    }
    
    .nav__link {
        color: #fff;
        font-size: 16px;
        line-height: 120%;
        font-weight: 800;
        text-transform: uppercase;
     
        
    }
    
}


.nav-right-side {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .btn.call {
        margin-right: 24px;
        margin-left: 28px;
    }
    
    .nav__lang {
        font-size: 32px;
        line-height: 35px;
        text-transform: uppercase;
        color: #FFFFFF;
        opacity: 0.2;
        margin-left: 24px;
    }
}
.nav__phone__wrapper {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    
    .time {
        font-size: 18px;
        line-height: 100%;
        color: #FFFFFF;
    }
    .phone {
        font-weight: 800;
        font-size: 36px;
        line-height: 115%;
        text-transform: uppercase;
        color: #FFFFFF;
        
    }
}
@media screen and (max-width: 1919px) {
    .center {
        width: 1024px;
    }
    nav {
        width: 1024px;
        justify-content: space-between;
    }
    .nav__menu {
        display: none;
    }
    
    .nav__phone__wrapper .phone {
        font-size: 32px;
        line-height: 35px;
    }
    .nav__phone__wrapper .time {
        font-size: 16px;
        line-height: 17px;
    }
}

@media screen and (max-width: 1139px){
    .center, nav {
        width: calc(100% - 30px);
        padding: 0 15px;
    }
    nav {
        padding-top: 20px;
    }
    
    .nav-right-side .btn.call {
        display: none;
    }
    
    .nav__logo {
        width: 80px;
        height: 25px;
    }
    .nav__phone__wrapper .phone {
        font-size: 24px;
        line-height: 26px;
    }
    
    
    
}
@media screen and (max-width: 539px) {
    
    .nav__logo {
        margin-right: 0;
    }
    .nav__phone__wrapper .phone {
        font-size: 22px;
line-height: 24px;
    }
    .nav__phone__wrapper .time {
        font-size: 14px;
line-height: 15px;
    }
    
    
    
}
