.main__screen {
    height: 100vh;
    width: 100%;
    background: url('../img/bg.png');
    padding-top: 10%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    .main__screen__wrapper {
        width: 100%;
        margin: auto;
    }
    
    .main__screen__header {
        font-weight: 800;
        font-size: 96px;
        line-height: 131px;
        color: #fff;
        text-transform: uppercase;
        text-align: center;
    }
    
    .main__screen__description {
        font-size: 24px;
        line-height: 150%;
        color: #fff;
        text-align: center;
        margin: 0 auto 20px;
        width: 70%;
    }
    
    .main__screen__choose {
        font-weight: 800;
        font-size: 36px;
        line-height: 49px;
        text-align: center;
        color: #fff;
        margin-bottom: 20px;
    }
    
   .service__wrapper{
       width: 75%;
       margin: auto;
    .service__item {
        position: relative;
        width: 250px;
        height: 75px;
       /*  background: linear-gradient(180deg, #FFFFFF 2.6%, #C4C4C4 100%); */
        border-radius: 10px;
        margin-right: 15px;
        margin-bottom: 15px;
        display: flex;
 
        align-items: center;
        padding: 6px 20px 6px 25px;
        box-sizing: border-box;
        cursor: pointer;
        border: 2px solid rgba(52, 139, 255, 0.1);
        
      /*   &::before {
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 10px;
            background: linear-gradient(0deg, #FFFFFF 2.6%, #C4C4C4 100%);
            transition: opacity 300ms linear;
            opacity: 0;
          }
        
        &:hover::before {
            opacity: 1;
          }
         */
         
         &:hover .service__icon
         {
             background: radial-gradient(50% 50% at 50% 50%, #FF000A 0%, rgba(255, 0, 10, 0) 100%);

         }
         
         
        .service__icon{
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
            margin-right: 20px;
            
            img {
                height: 100%;
                width: auto;
            }
        }
        
        .service__name {
            font-weight: 800;
            font-size: 16px;
            line-height: 120%;
            text-transform: uppercase;
            color: #fff;
            z-index: 1;
            
            span {
                font-weight: normal;
                font-size: 18px;
                line-height: 120%;
                text-transform: none;
            }
        }
        
        .blur-bg {
            background: radial-gradient(105.6% 352% at 0% -6.67%, rgba(52, 139, 255, 0.1) 0%, rgba(51, 138, 255, 0) 100%);
            
            border-radius: 10px;
            backdrop-filter: blur(5px);
       
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            
        }
        
    }
    
    .service__item:nth-child(3n){
        margin-right: 0;
    }
    
   }
   
   
    .service__row, .service__row--bottom{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .service__item--bottom {
        width: 382.5px;
        height: 75px;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 20px 6px 25px;
        box-sizing: border-box;
        cursor: pointer;
        
        .service__name--bottom {
            font-weight: 800;
            font-size: 16px;
            line-height: 120%;
            color: #fff;
            text-transform: uppercase;
            z-index: 1;
            
            span {
                font-size: 18px;
                line-height: 120%;
                color: #fff;
                font-weight: normal;
                text-transform: none;
            }
        }
        
        .service__icon--bottom {
            height: 50px;
            width: auto;
            z-index: 1;
            img {
                height: 100%;
                width: auto;
            }
        }
    }
    .service__item--bottom.blue {
        position: relative;
        background: linear-gradient(90deg, #006FA8 0%, #00164F 100%);
        margin-right: 15px;
        &::before {
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 10px;
            background: linear-gradient(90deg, #00164F 0%, #006FA8 100%);
            transition: opacity 300ms linear;
            opacity: 0;
          }
        &:hover::before {
            opacity: 1;
            
        }
       
    }
    .service__item--bottom.red {
        background: linear-gradient(90deg, #ED1C24 0%, #310000 99.55%);
        position: relative;
        &::before {
            position: absolute;
            content: "";
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 10px;
            background: linear-gradient(90deg, #310000 0%, #ED1C24 100%);
            transition: opacity 300ms linear;
            opacity: 0;
          }
        &:hover::before {
            opacity: 1;
            
        }
    }
    
    .call_craftsman__main {
        display: none;
    }
    
}
@media screen and (max-width: 1919px) {
    
    .main__screen {
        padding: 15% 0;
        height: auto;
    }
    
    .main__screen .main__screen__header {
        font-size: 64px;
        line-height: 71px;
        margin-bottom: 10px;
    }
    .main__screen .main__screen__description {
        font-size: 18px;
        line-height: 150%;
        margin-bottom: 45px;
    }
    .main__screen .main__screen__choose {
        display: none;
    }
    .main__screen .service__wrapper {
        width: 100%;
    }
   
    
}

@media screen and (max-width: 1139px) {
    
    .main__screen {
        padding: 33% 0;
    }
    
    .main__screen .service__wrapper {
        display: none;
    }
    .main__screen .main__screen__header {
        margin-bottom: 40px;
    }
    .main__screen .main__screen__description {
        width: 90%;
        font-size: 24px;
    }
    
    .main__screen .call_craftsman__main {
        display: block;
        width: 300px;
        height: 65px;
        margin: auto;
    }
}

@media screen and (max-width: 767px) {
    
    .main__screen {
        padding: 20% 0 10%;
    }
    
    .main__screen .main__screen__header {
        font-size: 36px;
        line-height: 40px;
        margin-bottom: 18px;
    }
    .main__screen .main__screen__description {
        font-size: 16px;
        margin-bottom: 18px;
        width: 100%;
    }
    
    .main__screen .call_craftsman__main {
        width: 200px;
        height: 45px;
    }
    
    
    
}

@media screen and (max-width: 539px)  {
    
    .main__screen {
        padding: 40% 0 30%;
    }
    
    
    .main__screen .main__screen__header {
        font-size: 24px;
        line-height: 26px;
        margin-bottom: 30px;
    }
    
    .main__screen .main__screen__description {
        margin-bottom: 30px;
    }
    
    
    
}